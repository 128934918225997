import React from "react"
import Layout from "../../components/layouts/Default"
import Container from "../../components/common/Container"
import H2 from "../../components/typography/H2"
import P from "../../components/typography/P"
import { Link } from "gatsby"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Newsletter angemeldet" desc="">
      <Container>
        <div className="max-w-2xl mx-auto">
          <H2 className="mb-4">
            Vielen Dank für Deine Anmeldung zu unserem Newsletter!
          </H2>
          <P className="mb-4">
            Die Einwilligung zur Speicherung Deiner persönlichen Daten und ihrer
            Nutzung für den Newsletterversand kannst Du jederzeit widerrufen. In
            jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem
            kannst Du jederzeit auch direkt Deinen entsprechenden Wunsch zur
            Abmeldung unseres Newsletters über unser{" "}
            <Link to="/newsletter/abmelden/" className="underline">
              Formular
            </Link>{" "}
            an uns übermitteln.
          </P>
          <P>
            Mehr Informationen dazu erhälst Du in unserer{" "}
            <Link to="/datenschutz/" className="underline">
              Datenschutzerklärung
            </Link>
            .
          </P>
        </div>
      </Container>
    </Layout>
  )
}
